.quickview {
    .quickview {
        margin-bottom: 15px;
    }

    .quickview__main {
        padding-left: 22px;
        padding-right: 22px;

        .icon {
            color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);
            -webkit-transition-duration: 0.2s;
            -o-transition-duration: 0.2s;
            transition-duration: 0.2s;
        }

        .quickview__extra__item {
            text-align: left;
            display: inline-flex;
            flex-flow: wrap;

            .quickview__extra__item__value {
                display: inline-flex;
                flex-flow: wrap;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        display: inline-flex;
                    }
                }
            }
        }
    }

    .element-name {
        font-weight: $fontWeightStrong;
    }

    .quickview__extra__item {
        font-weight: $fontWeightNormal;
    }

    .quickview__extra {
        color: $bg-dark-color;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        border-top: 2px solid #00000030;
        padding: 16px 22px 8px 22px;
        margin-top: 8px;

        @include media-breakpoint-up(md) {
            .quickview__extra__summary:not(:last-child) {
                border-right: 2px solid #00000030;
                padding-right: 22px;
            }
        }

        .quickview__extra__details {
            min-width: 15em;
            max-width: 15em;
            padding-left: 22px;

            @include media-breakpoint-down(md) {
                max-width: 100%;
                padding-left: 0;
            }

            .quickview__extra__item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                .quickview__extra__item__label {
                    font-weight: $fontWeightStrong;
                    padding-right: 10px;
                    margin-right: 15px;
                }

                @include media-breakpoint-down(md) {
                    margin-right: 0 !important;

                    .quickview__extra__item__value {
                        text-align: right;
                    }
                }
            }

            .details__content__header {
                font-weight: $fontWeightStrong;
                margin-bottom: 16px;
            }

            .details__content__section {
                margin-bottom: 8px;
            }
        }

        a {
            text-decoration: none;
            &::before {
                content: 'keyboard_arrow_right';
                font-family: 'Material Icons', serif;
                vertical-align: middle;
            }
        }
    }

    &:hover:not(.quickview-widget) {
        cursor: pointer;

        .quickview__main {
            color: var(--colorSecondary, $secondary-color);

            .expander-icon {
                color: var(--colorButtonPrimaryActiveBg, $defaultColorButtonPrimaryActiveBg);
                transform: scale(1.25);
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin: 17px 0;

        .quickview__main {
            padding-left: 14px;
            padding-right: 14px;
        }

        .quickview__extra__item, .details__content__section, .details__content__section div {
            font-size: $fontSizeSM;
        }

        .quickview__extra {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;

            .quickview__extra__summary {
                font-size: $fontSizeBase;
                border-right: 0;
            }

            .quickview__extra__details {
                border: none;
            }
        }
    }

    &.quickview-widget {
        box-shadow: 0 5px 13px #00000013;
        -webkit-box-shadow: 0 5px 13px #00000013;

        .quickview__main {
            .quickview__main-attributes {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
                gap: 0 20px;
            }
        }

        .quickview__extra {
            border-color: #F3F3F3;
        }
    }
}

.tab-content {
    @include media-breakpoint-down(sm) {
        .expander-icon {
            display: none;
        }
    }
}

.quickview__extra__item__value {
    display: inline-flex;
    flex-flow: wrap;
}