// Setup variables before Bootstrap
@import "typography.scss";
@import "variables.scss";

@import "~@42.nl/ui/dist/main";
@import "~tippy.js/dist/tippy.css";
@import "main.scss";
@import "generic.scss";
@import "imports.scss";
@import "product-overview";

@tailwind base;
@tailwind components;
@tailwind utilities;