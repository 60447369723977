.compact {
  overflow: hidden;
  max-height: 210px;
  position: relative;
}

.compact:after {
  content: '';
  position: absolute;
  top: 180px;
  width: 100%;
  height: 30px;
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from($gray-100-rgba-0),
                  to($gray-100-rgba-1)
  );
  background: -o-linear-gradient(
                  top,
                  $gray-100-rgba-0,
                  $gray-100-rgba-1
  );
  background: linear-gradient(
                  to bottom,
                  $gray-100-rgba-0,
                  $gray-100-rgba-1
  );
}

.compact-toggle {
  color: var(--colorPrimary, $primary-color);
  cursor: pointer;
  display: inline-block;
  margin-top: 15px;

  &:hover, &:focus {
    color: var(--colorSecondary, $defaultColorSecondary);
  }
}