.EnvBanner {
  z-index: 9000;
  position: fixed;
  top: 15px;
  width: 310px;
  padding: 1px 0;
  background: #c00;
  color: #eee;
  box-shadow: 0 0 10px rgba(0,0,0,.5);
  right: -127px;
  transform: rotate(40deg);
  opacity: 0.9;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: none;
  }

  span {
    display: block;
    padding: 1px 0;
    height: 24px;
    line-height: 24px;
    color: inherit;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid rgba(255,255,255,.3);
    text-shadow: 0 0 10px rgba(0,0,0,.31);
  }

  &:hover {
    opacity: 0.5;
  }
}
