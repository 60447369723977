/* AntDesign */
// Disable sticky columns on mobile
@media only screen and (max-width: 640px) {
  .ant-table-cell--widget.ant-table-cell--widget--fixed {
    position: relative !important;

    &::after {
      box-shadow: none !important;
    }
  }

  .ant-table-content {
    overflow: auto !important;
    > table {
      min-width: 520px;
      table-layout: auto !important;
    }
  }
}

.ant-table-content {
  overflow: auto hidden !important;

  thead.ant-table-thead{
    top: 0;
  }

  .ant-table-thead th,
  tr.ant-table-measure-row:first-child ~ tr:nth-of-type(2n + 1) td,
  tr:nth-child(even) td {
    background-color: var(
      --colorTableOddBg,
      $defaultColorTableOddBg
    ) !important;
  }

  tr.ant-table-measure-row:first-child ~ tr:nth-of-type(2n + 2) td {
    background-color: $white !important;
  }
}

$max-nesting-level: 10;
$indent-size: 17px;
$color-table-odd-bg: var(--colorTableOddBg, $defaultColorTableOddBg);
$color-primary: var(--colorPrimary, $defaultColorPrimary);

.ant-table-row-indent:is(:not(.indent-level-0)) {
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: calc(100% + 2px);
    top: -1px;
    left: 0;
    pointer-events: none;
  }

  @for $i from 1 through $max-nesting-level {
    &.indent-level-#{$i}::before {
      width: calc($i * $indent-size);
      background: repeating-linear-gradient(
                      to right,
                      $color-table-odd-bg 0,
                      $color-table-odd-bg calc($indent-size - 1.5px),
                      $color-primary calc($indent-size - 1.5px),
                      $color-primary $indent-size
      );
    }
  }
}

.matrix-widget {
  tbody {
    cursor: pointer;
  }

  thead {
    .matrix-widget__cell {
      vertical-align: bottom;

      &.matrix-widget__cell--subject--title {
        .matrix-widget__cell--subject--title__contents {
          // just writing-mode: sideways-lr; is not supported in Chrome
          writing-mode: vertical-rl;
          text-orientation: sideways;
          transform: rotate(180deg);

          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
      }
    }
  }

  --tableHoverBg: var(--colorTableActiveHoverBg, $defaultColorTableActiveHoverBg);

  /**
   * No built-in column hover effects in Antd/CSS.
   * ::after hack requires overflow:hidden and z-index, breaking horizontal scroll and sticky columns.
   * JS event listeners (even delegating) are avoided for performance and re-render risks.
   * Solution below is not pretty, but less bug-prone, memory-efficient and highly performant.
  */
  $widgetAndSubjectColumnsMaxCount: 30;
  @for $i from 1 through $widgetAndSubjectColumnsMaxCount {
    &:has(
      tr:nth-child(2) th:nth-child(#{$i} of .matrix-widget__cell--subject):hover,
      td:nth-child(#{$i} of .matrix-widget__cell--subject):hover
    ) {
      th:nth-child(#{$i} of .matrix-widget__cell--subject),
      td:nth-child(#{$i} of .matrix-widget__cell--subject) {
        --activeBg: var(--tableHoverBg);
      }
    }

    &:has(
      th:nth-child(#{$i} of .matrix-widget__cell--widget):hover,
      td:nth-child(#{$i} of .matrix-widget__cell--widget):hover
    ) {
      th:nth-child(#{$i} of .matrix-widget__cell--widget),
      td:nth-child(#{$i} of .matrix-widget__cell--widget) {
        --activeBg: var(--tableHoverBg);
      }
    }
  }

  .matrix-widget__cell {
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;

    &.matrix-widget__cell--hover {
      background-color: var(--activeBg);

      &:hover {
        background-color: var(--colorTableActiveHoverBg, $defaultColorTableActiveHoverBg) !important;
      }
    }

    &.matrix-widget__cell--info {
      max-width: 420px !important;
    }

    &.ant-table-cell-row-hover {
      background-color: var(--colorTableActiveHoverBg, $defaultColorTableActiveHoverBg) !important;
    }
  }

  .matrix-widget__cell--subject:not(.matrix-widget__cell--subject--title) {
    padding: 0 !important;

    .matrix-widget__cell--subject__contents {
      height: 44px;
    }
  }
}

/* Deprecated Reactstrap */
.table-striped > tbody > tr:nth-child(odd) {
  td {
    background-color: var(--colorTableOddBg, $defaultColorTableOddBg);
  }
}

.field-table {
  .table-header {
    height: 41px;
  }

  .table-filter {
    height: 49px;
  }
}

.table-striped > tbody > .table-active > td {
  background-color: $defaultColorTableActiveBg !important;
}

.table-responsive {
  overflow-y: hidden;
}

table {
  > thead > tr > th {
    font-weight: $fontWeightStrong;
  }

  td {
    vertical-align: middle;
    box-shadow: none !important;
  }
}

.table--condensed {
  tbody.ant-table-tbody {
    td {
      padding: 4px 6px !important;
    }
  }
}
