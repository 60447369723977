.color-primary {
  color: var(--colorPrimary, $primary-color);
}

.bg-primary {
  background-color: $bg-color !important;
}

.clickable {
  cursor: pointer;
}

.cursor--default {
  cursor: default !important;
}

.cursor--pointer {
  cursor: pointer !important;
}