.navbar-nav {
  width: 100%;
  max-width: 1170px;

  .btn:hover,
  .btn[aria-expanded='true'] {
    transform: scale(1.1);
  }
}

.extra-nav {
  padding: 2.5em 0;

  .mobile-menu-toggler {
    margin-left: 15px;
  }
}

.topbar {
  position: relative;
  &:not(.is-data-only){
    &:after {
      content: '';
      width: 100%;
      height: 4px;
      background: var(--colorEnvBaseBg, $primary-color);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.topbar > .main-nav {
  font-size: $fontSizeLG;
  width: 100%;
  position: relative;
  z-index: 60;

  li {
    position: relative;
    height: 46px;


    &:not(:last-child) {
      margin-right: 15px;
    }

    @include media-breakpoint-up(md) {
      &:after {
        content: '';
        position: absolute;
        background: var(--colorEnvBaseBg, $primary-color);
        height: 4px;
        bottom: 0;
        width: 100%;
        left: 0;
      }
    }

    &.tab-active {
      &:after {
        background: $bg-color;
      }
    }


    a {
      height: 100%;
      display: inline-flex;
      align-items: center;
      position: relative;
      color: $bg-dark-color;
      text-align: left;
      text-decoration: none;
      white-space: nowrap;
      border-radius: 5px 5px 0 0;
      -webkit-font-smoothing: antialiased;
      transition: all 300ms;  
      transform: translateY(4px);

      &:hover {
        transform: translateY(0px);
        background-color: #e6e8e9;
      }

      &:before {
        content: '';
        position: absolute;
        border-radius: 5px 5px 0 0;
        background-color: $bg-light-color;
        box-shadow: 0 0 0 2px $bg-gray-color;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      @include media-breakpoint-up(md) {
        &:before {
          box-shadow: 0 0 0 2px $bg-gray-color inset;
        }
      }

      &.active {
        transform: translateY(0px);
        background-size: 100%;
        background-position-y: 100%;
        position: relative;

        &:before {
          box-shadow: 0 0 0 4px var(--colorEnvBaseBg, $primary-color);
          background: $bg-color;
        }

        .main-tab__content-wrapper {
          > span {
            &:after {
              background: linear-gradient(
                to right,
                rgba($bg-color, 0) 0%,
                rgba($bg-color, 1) 100%
              );
            }
          }
        }
      }

      .main-tab__content-wrapper {
        overflow: hidden;
        padding: 0 25px 0;

        > span {
          display: inline-block;
          position: relative;
          overflow: visible;

          &:after {
            content: '';
            background: linear-gradient(
              to right,
              rgba($bg-light-color, 0) 0%,
              rgba($bg-light-color, 1) 100%
            );
            position: absolute;
            left: 100%;
            width: 23px;
            height: 100%;
          }
          > span {
            display: inline-block;
          }
        }
      }
    }
  }
}


.topbar {
  &:not(.is-data-only){
    background-color: white;
  }

  .dropdown {
    .dropdown-toggle {
      &.nav-link {
        margin-left: 0;
      }
    }

    .dropdown-menu {
      display: none !important;
    }

    &.show {
      .dropdown-menu {
        position: absolute;
        will-change: transform;
        top: 0;
        left: 0;
        -webkit-transform: translate3d(0px, 38px, 0px);
        transform: translate3d(0px, 38px, 0px);
        display: block !important;
      }
    }
  }

  .nav-link {
    color: inherit;
  }

  button.nav-link {
    &:hover {
      text-decoration: underline;
    }
  }

  > .navbar-nav {
    padding-left: $app-frame-xl-padding-x;
    padding-right: $app-frame-xl-padding-x;

    @include media-breakpoint-down(xl) {
      padding-left: $app-frame-padding-x;
      padding-right: $app-frame-padding-x;
    }

    .btn.mobile-menu-toggler {
      padding-right: 0rem !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .extra-nav {
    padding: 5px 17px;
  }

  .topbar {
    border-width: 3px;
    padding: inherit;

    > .main-nav {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px $app-frame-padding-x 0px $app-frame-padding-x !important;

      a {
        flex: 0 0 calc(100% / 3 - 6px) !important;
        padding: 8px 20px !important;
        border-radius: 10px 10px 0 0;

        &:not(:last-child) {
          margin-right: 9px;
        }

        &.active {
          padding-top: 7px !important;
          border-width: 3px;
          margin-bottom: -3px;
        }
      }
    }

    > .navbar-nav {
      .btn.mobile-menu-toggler {
        padding-right: 0rem !important;
      }
    }
  }
}

.navbar {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
