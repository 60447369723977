@mixin font-face-mixin($fontFamilyName, $fontStyle, $fontWeight, $fontFileName) {
    @font-face {
        font-family: $fontFamilyName;
        src: url('../../fonts/#{$fontFileName}.ttf') format('truetype');
        font-weight: $fontWeight;
        font-style: $fontStyle;
    }
}

// CamelCased for Bootstrap compatibility.
$fontFamilySourceCodePro: 'SourceCodePro';
$fontFamilySourceSans3: 'SourceSans3';

@include font-face-mixin($fontFamilySourceCodePro, normal, normal, 'SourceCodePro-Regular');
@include font-face-mixin($fontFamilySourceSans3, normal, normal, 'SourceSans3-Regular');
@include font-face-mixin($fontFamilySourceSans3, italic, normal, 'SourceSans3-Italic');
@include font-face-mixin($fontFamilySourceSans3, normal, 600, 'SourceSans3-SemiBold');
@include font-face-mixin($fontFamilySourceSans3, italic, 600, 'SourceSans3-SemiBoldItalic');

$fontFamily: $fontFamilySourceSans3, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontFamilyMonospace: $fontFamilySourceCodePro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Root font size set with % to respect the user-adjustable browser font size (typically 16px) for accessibility.
// (62.5/100) * 16px is 10px, simplifying rem calculations: 1rem = 10px.
$fontSizeRoot: 62.5%;
$fontSizeSM: 1.3rem; // Should be 1.2rem
$fontSizeBase: 1.5rem; // Should be 1.4rem
$fontSizeLG: 1.6rem;
$fontSizeXL: 2rem;
$fontSizeXXL: 2.4rem;

$fontWeightItalic: 400;
$fontWeightNormal: 400;
$fontWeightStrong: 600;

// Unquoted for Bootstrap's usage of CSS variables
$lineHeightAddition: 0.57rem;
$lineHeightSM: unquote("calc(#{$fontSizeSM} + #{$lineHeightAddition})");
$lineHeightBase: unquote("calc(#{$fontSizeBase} + #{$lineHeightAddition})");
$lineHeightLG: unquote("calc(#{$fontSizeLG} + #{$lineHeightAddition})");
$lineHeightXL: unquote("calc(#{$fontSizeXL} + #{$lineHeightAddition})");

/* Bootstrap v5 typography overrides */
$font-family-sans-serif: $fontFamily;
$font-family-monospace: $fontFamilyMonospace;
$font-family-base: $fontFamily;
$font-family-code: $fontFamilyMonospace;

$headings-font-family: $fontFamily;
$headings-font-weight: $fontWeightNormal;

$enable-rfs: false;
$font-size-root: $fontSizeRoot;
$font-size-base: $fontSizeBase;
$h1-font-size: $fontSizeXXL;
$h2-font-size: $fontSizeXL;
$h3-font-size: $fontSizeLG;
$h4-font-size: $fontSizeBase;
$h5-font-size: $fontSizeBase;
$h6-font-size: $fontSizeBase;
$font-size-sm: $fontSizeSM;
$font-size-lg: $fontSizeLG;
$btn-font-size: $fontSizeLG !important;
$input-font-size: $fontSizeLG;
$input-btn-font-size: $fontSizeLG;

/* Utility classes */
@mixin font-mixin($weight, $lineHeight, $fontSize, $style: normal, $decoration: none) {
    font-family: $fontFamily;
    font-weight: $weight;
    font-style: $style;
    line-height: $lineHeight;
    font-size: $fontSize;
    text-decoration: $decoration;
}

.font-base-normal {
    @include font-mixin($fontWeightNormal, $lineHeightBase, $fontSizeBase);
}

.font-base-strong {
    @include font-mixin($fontWeightStrong, $lineHeightBase, $fontSizeBase);
}

.font-base-underline {
    @include font-mixin($fontWeightNormal, $lineHeightBase, $fontSizeBase, normal, underline);
}

.font-base-delete {
    @include font-mixin($fontWeightNormal, $lineHeightBase, $fontSizeBase, normal, line-through);
}

.font-base-italic {
    @include font-mixin($fontWeightItalic, $lineHeightBase, $fontSizeBase, italic);
}

.font-sm-normal {
    @include font-mixin($fontWeightNormal, $lineHeightSM, $fontSizeSM);
}

.font-sm-strong {
    @include font-mixin($fontWeightStrong, $lineHeightSM, $fontSizeSM);
}

.font-sm-underline {
    @include font-mixin($fontWeightNormal, $lineHeightSM, $fontSizeSM, normal, underline);
}

.font-sm-delete {
    @include font-mixin($fontWeightNormal, $lineHeightSM, $fontSizeSM, normal, line-through);
}

.font-sm-italic {
    @include font-mixin($fontWeightItalic, $lineHeightSM, $fontSizeSM, italic);
}

.font-lg-normal {
    @include font-mixin($fontWeightNormal, $lineHeightLG, $fontSizeLG);
}

.font-lg-strong {
    @include font-mixin($fontWeightStrong, $lineHeightLG, $fontSizeLG);
}

.font-lg-underline {
    @include font-mixin($fontWeightNormal, $lineHeightLG, $fontSizeLG, normal, underline);
}

.font-lg-delete {
    @include font-mixin($fontWeightNormal, $lineHeightLG, $fontSizeLG, normal, line-through);
}

.font-lg-italic {
    @include font-mixin($fontWeightItalic, $lineHeightLG, $fontSizeLG, italic);
}

.font-xl-normal {
    @include font-mixin($fontWeightNormal, $lineHeightXL, $fontSizeXL);
}

.font-xl-strong {
    @include font-mixin($fontWeightStrong, $lineHeightXL, $fontSizeXL);
}

.font-xl-underline {
    @include font-mixin($fontWeightNormal, $lineHeightXL, $fontSizeXL, normal, underline);
}

.font-xl-delete {
    @include font-mixin($fontWeightNormal, $lineHeightXL, $fontSizeXL, normal, line-through);
}

.font-xl-italic {
    @include font-mixin($fontWeightItalic, $lineHeightXL, $fontSizeXL, italic);
}

.font-heading-1 {
    @include font-mixin($fontWeightStrong, $h1-font-size + $lineHeightAddition, $h1-font-size);
}

.font-heading-2 {
    @include font-mixin($fontWeightStrong, $h2-font-size + $lineHeightAddition, $h2-font-size);
}

.font-heading-3 {
    @include font-mixin($fontWeightStrong, $h3-font-size + $lineHeightAddition, $h3-font-size);
}

.font-heading-4 {
    @include font-mixin($fontWeightStrong, $h4-font-size + $lineHeightAddition, $h4-font-size);
}

.font-heading-5 {
    @include font-mixin($fontWeightStrong, $h5-font-size + $lineHeightAddition, $h5-font-size);
}
