.ant-slider {
  .ant-slider-mark-text {
    padding: 0 5px;
    background: linear-gradient(to bottom, transparent 5px, $gray-100 5px);
    border-radius: 99px;

    &:first-child {
      z-index: 3 !important;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    white-space: normal;
    word-wrap: break-word;
  }

  .btn--show-more-toggle.filter--product--paged__show-more {
    border-radius: 0 !important;
    border-top: 1px solid $separator-color !important;
    padding-top: 10px !important;
  }
}

.ant-select-dropdown, .ant-picker-dropdown {
  z-index: 200;
}

.filter__modal {
  .modal-body {
    max-height: 46vh;
    overflow-y: auto;

    .query-tags {
      max-height: 20vh;
      overflow-y: auto;
    }

    .filters {
      max-width: unset;
    }

    .anticon-frown svg {
      fill: var(--colorPrimary, $primary-color);
    }
  }

  .modal-footer {
    .filter__modal__load-more-button button:disabled {
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      i {
        animation: spin 1s linear infinite;
      }
    }
  }
}

.btn--toggle {
  width: 100%;

  .btn {
    &.btn-secondary {
      width: calc(100% + 20px);
      color: var(--colorPrimary, $defaultColorPrimary);
      background-color: transparent;
      border: none;
      padding-left: 10px;
      margin-left: -10px;

      &:hover, &:focus, &:active, &:active:hover {
        color: var(--colorSecondary, $defaultColorSecondary);
        background-color: inherit !important;
        font-weight: 600;
      }

      > div {
        justify-content: start !important;
      }
    }
  }

  &.btn--toggle--more-less {
    i.icon {
      margin-left: -3px;
    }
  }
}

