/* Fallbacks from TimeEdit theme (https://themes.timeedit.net/timeedit-2024.css) */
$defaultColorPrimary: #007644;
$defaultColorSecondary: #015C39;
$defaultColorTextBase: rgba(0, 0, 0, 0.88);

$defaultColorFooterBg: #f5f5f5;
$defaultColorEnvBaseBg: $defaultColorPrimary;
$defaultColorEnvBaseText: #ffffff;
$defaultColorButtonPrimaryBg: $defaultColorPrimary;
$defaultColorButtonPrimaryActiveBg: $defaultColorSecondary;
$defaultColorHeadingPrimaryText: $defaultColorPrimary;
$defaultColorInputBorder: $defaultColorPrimary;
$defaultColorTableOddBg: #f5f5f5;
$defaultColorTableActiveBg: #8bf7b8;
$defaultColorTableActiveHoverBg: #e1fff2;
$defaultColorTableHoverBg: #e1fff2;
$defaultColorTableHoverText: $defaultColorPrimary;

/* Generic colors */
$white: #fff !default;
$gray-100: #fcfcfd !default;
$gray-100-rgba-0: rgba(252, 252, 253, 0) !default;
$gray-100-rgba-1: rgba(252, 252, 253, 1) !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$separator-color: #d5d8db;

/* Bootstrap overrides */
$primary-color: $defaultColorPrimary;
$secondary-color: $defaultColorSecondary;
$text-color: var(--colorTextBase, $defaultColorTextBase);
$link-hover-color: #063640;

$bg-color: $gray-100;
$bg-light-color: $gray-200;
$bg-gray-color: $gray-400;
$bg-dark-color: $gray-900;

$spacer: 1.6rem;

$input-btn-padding-x: 1.2rem;
$input-btn-padding-x-sm: $input-btn-padding-x;
$input-btn-padding-x-lg: $input-btn-padding-x;

$input-btn-padding-y: 0.6rem;
$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-padding-y-lg: $input-btn-padding-y;

$input-border-radius: 0.4rem;
$border-color: rgba(0, 0, 0, .25);

$btn-border-radius: 0.4rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

$modal-header-padding: 1.6rem;

$table-border-color: $separator-color;

$nav-tabs-border-color: $gray-300;
$nav-tabs-link-active-bg: #f4f6f8;
$nav-tabs-link-active-border-color: $gray-300;
$nav-link-font-size: 1.6rem;
$nav-link-padding-x: 1.6rem;
$nav-link-padding-y: .8rem