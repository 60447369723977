$app-frame-padding-x: 20px;
$app-frame-xl-padding-x: 5px;

.main-wrapper {
  position: relative;
  z-index: 100;
  flex-grow: 1;
  background: $bg-color;
}

.app_main {
  padding: $app-frame-xl-padding-x;
  max-width: 1170px;
  margin: auto;
  min-height: calc(100vh - 290px);

  .breadcrumb {
    background: transparent;
    padding: 0 !important;
    margin: 2.5rem 0 5rem 0 !important;

    .breadcrumb-item {
      + .breadcrumb-item {
        &::before {
          content: 'keyboard_arrow_right';
          font-family: 'Material Icons', serif;
          vertical-align: middle;
        }
      }

      .active {
        color: #2b2b2b !important;
      }

      a:hover {
        text-decoration: underline !important;
      }
    }

    a {
      color: #2b2b2b !important;
    }
  }

  .search-button {
    width: 90px;
    border-radius: 0 10px 10px 0;
    color: $white;
  }

  @include media-breakpoint-down(xl) {
    padding: 0 $app-frame-padding-x;
  }
}
